<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../../assets/left.png"
             alt
             @click="goBack" />
        <span class="center">合同管理</span>
        <img src="../../../assets/right.png"
             alt />
        <span class="center">合同模板</span>
        <img src="../../../assets/right.png"
             alt />
        <span class="center">{{ title }}</span>
        <span class="clickColor"
              @click="goBack">返回</span>
      </div>
      <div>
        <el-button type="primary"
                   size="mini"
                   class="top_btn"
                   @click="setPosition('ruleForm')">指定盖章位置</el-button>
        <el-button type="primary"
                   size="mini"
                   class="top_btn"
                   @click="preview('ruleForm')">预览</el-button>
        <el-button type="primary"
                   size="mini"
                   class="top_btn"
                   @click="save('ruleForm')"
                   :loading="loading">保存</el-button>
      </div>
    </div>
    <el-card>
      <div style="margin-top: 10px">
        <el-form :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="120px"
                 class="demo-ruleForm"
                 label-position="top"
                 style="text-align: left">
          <el-form-item label="合同模板名称"
                        prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="合同模板类型"
                        prop="type">
            <el-select style="display: block"
                       v-model="ruleForm.type"
                       placeholder="请选择合同模板类型"
                       clearable
                       disabled>
              <!-- <el-option label="入职合同" value="1"></el-option>
              <el-option label="离职合同" value="2"></el-option> -->
              <el-option label="用工合同"
                         value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同模板描述"
                        prop="desc">
            <el-input type="textarea"
                      v-model="ruleForm.desc"
                      rows="3"></el-input>
          </el-form-item>
          <el-form-item label="模板内容"
                        prop="content">
            <TinyEditor v-model="ruleForm.content"
                        :configStr="'defined_text'"></TinyEditor>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
// import { loadjs } from "/src/lib/load";
// import getExtendElement from "/src/lib/editor-extends/template-element.js";
import { api } from '/src/api/base';
const request = api('/contractTemplate');
export default {
  components: {
    TinyEditor: () => import('/src/components/tinyEditor')
  },
  data () {
    return {
      loading: false,
      editor: null,
      ruleForm: {
        name: '',
        desc: '',
        type: '3',
        content: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入合同模板名称', trigger: 'blur' }
        ],
        type: [{ required: false, message: '请选择合同类型', trigger: 'blur' }],
        desc: [
          { required: true, message: '请输入合同模板描述', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入模板内容', trigger: 'blur' }
        ]
      },
      code: '',
      title: '新建合同模板',
      fixed: false
    };
  },
  created () {
    // this.loadEditor();
    this.code = this.$route.query.code;
    if (this.code) {
      this.title = '编辑合同模板';
      this.getTemplate();
    }
  },
  mounted () {
    // window.addEventListener("scroll", this.handleScroll, true);
  },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll, true);
  // },
  methods: {
    // async loadEditor() {
    //   await loadjs("/editor.js");
    //   const E = window.wangEditor;
    //   const editor = new E("#toolbar-container", "#text-container");
    //   editor.config.zIndex = 800;
    //   editor.config.height = "auto";
    //   editor.config.excludeMenus = [
    //     "link",
    //     "todo",
    //     "video",
    //     "quote",
    //     "code",
    //     "emoticon",
    //     "video"
    //   ];
    //   const m = getExtendElement(E);
    //   E.registerMenu("menuKey", m);
    //   editor.create();
    //   editor.txt.html("<div>模板内容</div>");
    //   this.editor = editor;
    // },
    async getTemplate () {
      const res = await request('contractTemplate.describe.json')({
        code: this.code
      });
      this.ruleForm.name = res.name;
      this.ruleForm.desc = res.desc;
      this.ruleForm.type = res.type;
      // this.editor.txt.html(res.content);
      this.ruleForm.content = res.content;
    },
    goBack () {
      this.$router.push({
        path: '/system/contractTemplate/list'
      });
    },
    setPosition (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const params = {
            ...this.ruleForm
          };
          if (this.code) {
            await request('contractTemplate.edit.json')({
              ...params,
              code: this.code
            });
            this.$router.push({
              path: '/system/contract/pdf',
              query: {
                code: this.code,
                type: 'template'
              }
            });
          } else {
            var res = await request('contractTemplate.add.json')({
              ...params,
              code: this.code
            });
            this.code = res.code;
            this.$router.push({
              path: '/system/contract/pdf',
              query: {
                code: this.code,
                type: 'template'
              }
            });
          }
        }
      });
    },
    preview (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const params = {
            ...this.ruleForm
            // content: _content
          };
          if (this.code) {
            await request('contractTemplate.edit.json')({
              ...params,
              code: this.code
            });
            this.$router.push({
              path: '/system/contractTemplate/detail',
              query: { code: this.code }
            });
          } else {
            var res = await request('contractTemplate.add.json')(params);
            this.code = res.code;
            console.log(params);
            this.$router.push({
              path: '/system/contractTemplate/detail',
              query: { code: this.code, name: 'add' }
            });
          }
        }
      });
    },
    save (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          // const _content = this.editor.txt.html();
          const params = {
            ...this.ruleForm
            // content: _content
          };
          if (this.code) {
            await request('contractTemplate.edit.json')({
              ...params,
              code: this.code
            });
            // this.$message.success("合同模板编辑成功！");
          } else {
            var res = await request('contractTemplate.add.json')(params);
            console.log(res.code);
            this.code = res.code;
            this.$message.success('合同模板创建成功！');
          }
        } else {
          return false;
        }
      });
    },

    // handleScroll() {
    //   let scrollTop = this.$refs.toolbar.getBoundingClientRect().top;
    //   this.fixed = scrollTop < 60;
    // }
  }
};
</script>

<style>
.toolbar {
  width: 21cm;
  margin: 0 auto;
  border: 1px solid #c9d8db;
}

.tool_fixed {
  position: absolute;
  top: 60px;
  z-index: 900;
  width: 100%;
  left: -4px;
  right: 0;
}
</style>
